import screenshot from './lofi-screenshot.png'
import gtag from 'ga-gtag'

export default function App() {

  function trackDownloadEvent(appVersion) {
    gtag('event', 'download_app', {
      app_version: appVersion,
    })
  }

  return (
    <div className="flex flex-col items-center">
      <h1 className="mt-20 text-5xl font-bold text-gray-900">
        LofiBox
      </h1>
      <div className="mt-10 text-2xl text-center">
        Listen to your favorite lofi mix without opening Youtube
      </div>
      <div className='mt-10 flex'>
        <button className="mx-5 my-2 bg-green-500 text-white text-xl font-bold py-2 px-4 rounded inline-flex items-center">
          <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
          <a href="https://github.com/yuandongzhong/lofibox_dl/releases/download/0.1.0/lofibox-0.1.0-arm64.dmg" 
            onClick={() => trackDownloadEvent('mac_apple_sillicon')}>Download (Apple Sillicon)</a>
        </button>
        <button className="mx-5 my-2 bg-blue-500 text-white text-xl font-bold py-2 px-4 rounded inline-flex items-center">
          <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
          <a href="https://github.com/yuandongzhong/lofibox_dl/releases/download/0.1.0/lofibox-0.1.0.dmg" 
            onClick={() => trackDownloadEvent('mac_intel')}>Download (Intel Mac)</a>
        </button>
      </div>
      <img src={screenshot} className="mt-20 lg:w-1/2 lg:h-auto md:w-4/6 h-64 object-cover object-center rounded" alt="lofi screenshot"></img>
      <div className="mt-20 text-2xl font-bold text-gray-900">
        Why made this?
      </div>

      <div className="lg:w-1/2 my-5 p-10">
        I like to listen to Lo-Fi radio or mix on Youtube while coding. It helps me to stay calm, focused, and relaxed. However, the ads and other videos from the platform are distracting and annoying. Therefore, I build this simple app so I can listen to my favorite mix without the need to open Youtube. In fact, you can listen to anything on Youtube as long as you provide the link to the app. Hope you will like it.
      </div>
    

      <div className="mt-10 text-2xl font-bold text-gray-900">
        Who made this?
      </div>
      <div className="lg:w-1/2 my-5 p-10">
        Someone who likes software and music builds this app. You can say hi to me on <a className="underline text-blue-600 text-lg" href="https://twitter.com/pat_zhong">Twitter.</a>
      </div>
    </div>
  )
}